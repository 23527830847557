import React from "react";
import { Link } from "gatsby";

import SecondaryLanding from "../../layouts/secondary-landing";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import SEO from "../../components/seo/seo";

const AccountDetailsSpanish = () => {
  const breadcrumbData = [
    {
      id: 1,
      active: true,
      title: "Detalles de la cuenta"
    }
  ];

  const SEOData = {
    title: "Detalles de la cuenta",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "Detalles de la cuenta"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Aquí es donde encontrará los términos y divulgaciones de la cuenta, nuestro aviso de privacidad, nuestro programa actual de tarifas y otros detalles importantes sobre nuestros productos y servicios."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/detalles-de-la-cuenta"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Detalles de la cuenta</h1>
        <p>
          Gracias por ser un cliente valioso de WaFd Bank. Aquí es donde encontrará los términos y divulgaciones de la
          cuenta, nuestro aviso de privacidad, nuestro programa actual de tarifas y otros detalles importantes sobre
          nuestros productos y servicios.
        </p>
        <h2>Personal</h2>
        <p>
          <Link to="/es/privacidad-y-seguridad" id="banking-privacy-security-page-link">
            Privacidad y seguridad
          </Link>
          <br />
          <a
            title="Lista de tarifas al consumidor, cargos por servicio"
            href="/documents/schedule-of-consumer-fees-service-charges-effective.pdf"
            target="_blank"
            id="schedule-of-consumer-fees-service-charges-pdf"
          >
            Lista de tarifas al consumidor, cargos por servicio
          </a>
          <br />
          <a
            title="Acuerdo y divulgaciones de la cuenta de depósito personal"
            href="/documents/wafdbank-consumer-disclosure-guide.pdf"
            target="_blank"
            id="schedule-of-consumer-fees-service-charges-2023-pdf"
          >
            Acuerdo y divulgaciones de la cuenta de depósito personal
          </a>
          <br />
          <a
            title="Proceso de financiamiento de la cuenta en línea"
            href="/documents/online-account-funding-process.pdf"
            target="_blank"
            id="online-account-funding-process-pdf"
          >
            Proceso de financiamiento de la cuenta en línea
          </a>
        </p>
      </section>
    </SecondaryLanding>
  );
};

export default AccountDetailsSpanish;
